<template>
  <div class="my-page-open my-page" :class="{skeleton : !state.loaded }">
    <ul class="nav">
      <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
        <router-link :to="m.path" :class="{'badge-point-soft' : $route.path === m.path}" class="no-underline">{{ m.title }}</router-link>
      </li>
    </ul>
    <div class="content">
      <template v-if="state.projects.length || !state.loaded">
        <ul class="tight">
          <li v-for="p in state.projects" :key="p.projectSeq" class="card">
            <div class="thumb">
              <span class="img" :style="{backgroundImage: `url('${computedThumbnailUrl(p.thumbFilePath)}')`, backgroundPosition: p.mainImagePosition || 'center'}"></span>
              <span class="badge badge-secondary-soft" v-if="p.badge !== undefined">{{ p.badge }}</span>
            </div>
            <div class="panel">
              <div class="title">
                <b>{{ p.projectName }}</b>
              </div>
              <ul class="tight info">
                <li v-if="p.period !== undefined">
                  <span>펀딩기간</span>
                  <span>{{ p.period }}</span>
                </li>
                <li v-if="p.partnership !== undefined">
                  <span>파트너십</span>
                  <span>{{ p.partnership }}</span>
                </li>
                <li v-if="p.createDate !== undefined">
                  <span>등록일시</span>
                  <span>{{ p.createDate }}</span>
                </li>
              </ul>
              <div class="actions">
                <template v-for="a in computedActions(p.projectSeq, p.regState)">
                  <a :href="a.link" target="_blank" rel="noopener noreferrer" class="font-sm btn btn-light" :title="a.title" v-if="a.visible" :key="`${component.name}Action${a.name}`" @click="a.click !== null ? a.click() : null">
                    <span class="img" :style="{backgroundImage: `url(${a.ico})`}"></span>
                    <span>{{ a.title }}</span>
                  </a></template>
              </div>
            </div>
          </li>
        </ul>
        <div class="page">
          <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
        </div>
      </template>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Card from "@/components/Card.vue";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import Pagination from "@/components/Pagination.vue";

function Component(initialize) {
  this.name = "pageMyPageOpen";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination, NoData, Card},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "진행 프로젝트");

      const paths = router.app.$route.path.split("/");
      state.projectType = paths[paths.length - 1];
      state.menu = state.menus[0];

      for (let i in state.menus) {
        if (state.menus[i].path === router.app.$route.path) {
          state.menu = state.menus[i];
          break;
        }
      }

      load();
    });

    const state = reactive({
      loaded: false,
      projectType: "",
      pagination: null,
      menu: {
        name: "",
        title: "",
        path: ""
      },
      menus: [
        {name: "reward", title: "후원형", path: "/mypage/open/reward"},
        {name: "invest", title: "증권형", path: "/mypage/open/invest"},
        {name: "mock", title: "모의 투자", path: "/mypage/open/mock"},
      ],
      projects: [{
        projectSeq: 211,
        projectName: "Please wait a moment",
        imgUrl: "/uploads/invest/main/MAIN_20211202045326925.jpg",
        regState: "Wait",
        title: "Please wait a moment",
        period: "Please wait a moment",
        badge: "Wait",
        partnership: "없음",
        createDate: "Please wait a moment",
      }, {
        projectSeq: 155,
        projectName: "Please wait a moment",
        imgUrl: "/uploads/invest/main/MAIN_20211202050102820.jpg?w=600&h=450&lossless=1",
        regState: "Wait",
        title: "Please wait a moment",
        period: "Please wait a moment",
        partnership: "없음",
        badge: "Wait",
        createDate: "Please wait a moment",
      }]
    });

    const computedThumbnailUrl = computed(() => {
      return (thumbFilePath) => {
        return store.getters.thumbnailUrl(thumbFilePath);
      };
    });

    const computedActions = computed(() => {
      return (projectSeq, regState) => {
        return [{
          name: "preview",
          title: "상세",
          link: `/${state.projectType === "invest" ? "invest" : "reward"}/preview/${projectSeq}`,
          ico: "/assets/ico/common.search.svg",
          click: null,
          visible: regState !== "STATUS_0006",
        }, {
          name: "direct",
          title: "상세",
          link: `/${state.projectType === "invest" ? "invest" : "reward"}/${projectSeq}`,
          ico: "/assets/ico/common.search.svg",
          click: null,
          visible: regState === "STATUS_0006",
        }, {
          name: "manage",
          title: "관리",
          link: `/manage/${state.projectType}/${projectSeq}`,
          ico: "/assets/ico/common.setting.linear.svg",
          click: null,
          visible: regState === "STATUS_0006",
        }, {
          name: "edit",
          title: "수정",
          link: `/apply/${projectSeq}`,
          ico: "/assets/ico/common.edit.svg",
          click: null,
          visible: state.projectType !== "invest" && ["STATUS_0001", "STATUS_0002", "STATUS_0003", "STATUS_0004"].includes(regState),
        }, {
          name: "remove",
          title: "삭제",
          link: null,
          ico: "/assets/ico/common.trash.svg",
          click: () => remove(projectSeq),
          visible: state.projectType !== "invest" && ["STATUS_0001", "STATUS_0002", "STATUS_0004"].includes(regState),
        },];
      };
    });

    const load = (num) => {
      const args = {
        pageIndex: num || 1,
        pageSize: 10,
        pageUnit: 10,
      };

      num && window.scrollTo({top: 0, left: 0, behavior: "smooth"});

      state.loaded = false;
      http.get("/api/build/projects/" + state.projectType, args).then(({data}) => {
        state.loaded = true;
        state.projects = [];
        state.pagination = data?.body?.paginationInfo;

        for (let p of data.body.list) {
          const durations = [];

          if (p.regState === "STATUS_0006") {
            switch (p.progressOrder) {
              case 0:
                p.badge = "펀딩 대기 중";
                break;

              case 1:
                p.badge = p.successFailFlag === "S" ? "펀딩 성공" : "진행 중";
                break;

              case 2:
                p.badge = p.successFailFlag === "S" ? "펀딩 성공" : p.successFailFlag === "F" ? p.fundingType === "A" ? "펀딩 실패" : "펀딩 마감" : "";
                break;
            }
          } else {
            p.badge = definitions.codes.obj.projectStatus[p.regState];
          }

          if (state.projectType === "invest") {
            p.partnership = (p.partnershipFlag === "Y" ? p.partnershipName || "(N/A)" : "아니오");

            if (p.investStartDate) {
              durations.push(lib.getDateFormat(p.investStartDate, "yyyy-MM-dd"));

              if (p.investEndDate) {
                durations.push(lib.getDateFormat(p.investEndDate, "yyyy-MM-dd"));
              }

              p.period = durations.join(" - ");
            } else {
              p.period += "(N/A)";
            }
          } else {
            p.partnership = (p.partnershipFlag === "Y" ? p.partnershipName || "(N/A)" : "아니오");

            if (p.investStartDate) {
              durations.push(lib.getDateFormat(p.investStartDate, "yyyy-MM-dd"));

              if (p.investEndDate) {
                durations.push(lib.getDateFormat(p.investEndDate, "yyyy-MM-dd"));
              }

              p.period = durations.join(" - ");
            } else {
              p.period = "(N/A)";
            }
          }

          if (!p.projectName) {
            p.projectName = "(N/A)";
          }

          state.projects.push(p);
        }
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const remove = (seq) => {
      store.commit("confirm", {
        message: "선택하신 프로젝트를 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/build/projects/${state.projectType}/${seq}`).then(() => {
            load();
            store.commit("setSwingMessage", "삭제하였습니다.");
          });
        }
      });
    };

    return {component, state, computedThumbnailUrl, computedActions, remove, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-open {
  .content {
    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: $px24;

      .card {
        border: 0;
        width: calc((100% - ($px24 * 2)) / 3);

        .thumb {
          border-radius: $thumbBorderRadius;
          background-color: $colorBackground;
          overflow: hidden;
          position: relative;
          width: 100%;
          font-size: 0;

          > .img {
            width: 100%;
            padding-top: 100%;
          }

          .badge {
            position: absolute;
            top: $px16;
            right: $px16;
            padding: $px4 $px8;
            font-size: $px14;
            border-radius: $px8;
          }
        }

        .panel {
          margin-top: $px16;
          padding: 0 $px8;

          .title {
            line-height: 1.2;
            height: $px40
          }

          > ul {
            display: flex;
            flex-direction: column;
            margin-top: $px4;

            li {
              font-size: $px14;

              span {
                display: inline-block;

                &:first-child {
                  width: $px60;
                }
              }
            }
          }

          .actions {
            display: flex;
            gap: $px16;
            align-items: center;
            margin-top: $px16;

            > a {
              white-space: nowrap;
              border-radius: $px100;
              display: flex;
              align-items: center;
              line-height: 1;
              gap: $px4;

              > .img {
                width: $px16;
                height: $px16;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content {
      > ul {
        .card {
          width: calc((100% - $px24) / 2);
        }
      }
    }
  }

  @media(max-width: 767px) {
    .content {
      > ul {
        .card {
          width: 100%;
        }
      }
    }
  }

  &.skeleton {
    .nav li a {
      @include skeleton;
    }

    .content > ul .card {
      .thumb {
        @include skeleton;

        .badge {
          @include skeleton;
        }
      }

      .panel {
        .title b,
        > ul li,
        .actions a,
        .actions a span {
          @include skeleton;
        }
      }
    }
  }
}
</style>